/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _, { isNull } from 'lodash';
import {
  commaSeperator,
  convertDateInTimezone,
  convertMilesToKm,
  convertMonthFromDate,
  // getPermissionStatus,
  getStatus,
  getTimeToShow,
  getUnit,
  rankingDashboard,
} from "../../../utils/methods";
import moment from 'moment';
import {connect} from "react-redux";
import Image from '../../Image';
import isEmpty from 'lodash/isEmpty';
import Waiting from '../../Waiting';
import {getChallengeDownloadCSVStatus, joinOrLeaveChallenge, getGroupChallengeCsvData, getInviteAmigosList, getUserListOfChallenges, getBuddiesListOfChallenges} from "../../../redux/actions/challengeActions";
import CSVList from "../../AttendeesModal/CSVList";
const InviteAmigoPopUp = React.lazy(()=> import("../InviteAmigoPopUp/index"));
const ParticipantsPopUp = React.lazy(()=> import("../participantsInChallengePopUp/index"));
import { MyTaskContainerV2, /*ChallengeButton, ButtonWrapper, PrintButton*/} from '../MyTaskTab/styles';
import { TeamMembers } from '../styles';
import { Border} from '../../EventDetails/styles';
import { withTranslation } from 'react-i18next';
import { ImageUrl } from '../../../utils/constants';
import LazyImage from '../../common/LazyImage/LazyImage';
import {ChallengeDateContainer, ChallengePointContainer} from '../styles'

let csvHeaders = [];
class TeamOverViewScreen extends Component {
  constructor(props){
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
      pageNumber: 1,
      selectedOption: 'all',
      isLoading:false,
      teamMembers:[{name:'DIpak', rank:'1st'}, {name:'Ketan', rank:'1st'}, {name:'Akash', rank:'1st'}]
    }
  }
  componentDidMount() {
    const { location: {pathname}, challenge, fetchTeamChallenge, getUserOverviewDetails, getChallengeCsvData, getChallengeDownloadCSVStatus, getGroupChallengeCsvData, getBuddiesListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    getChallengeDownloadCSVStatus(challenge.id);
    // getUserListOfChallenges(challenge.id);
    getBuddiesListOfChallenges(challenge.id, 1);
    if(challenge.challenge_type === 'group'){
      getGroupChallengeCsvData(challenge.id, challenge.event_type);
    }else{
      getChallengeCsvData(challenge.id, challenge.event_type, 'all');
    }
    getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    if(challenge.challenge_type === 'survivor' && challenge.challenge_mode == 'Team') {
      fetchTeamChallenge(StringArray[(StringArray.length - 1)], challenge.event_type);
    }
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);

  }
  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeLoading(bool) {
    this.setState({ isLoading: bool })
  }


  showProfileImage = () => {
    const {profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if(isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  printAction = (bool) => {
    const{challengeCsvData, challenge} =  this.props;
    const ChallengeType = challenge && challenge['event_type'].split(' ')[1];
    let updatedChallengeCSV = challengeCsvData && challengeCsvData.length > 0 && challenge.challenge_type === 'new_group' ?
      challengeCsvData.map((competitor) => {
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = competitor['user_rank'];
        obj['EMAIL'] = competitor['EMAIL'];
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        return obj;
      })
      : challengeCsvData.map((competitor, index)=>{
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = index+1;
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        obj['Daily Average'] = challenge['challenge_type'] === 'group' ? competitor['daily_average'] : competitor['challenge_average'];
        obj['Weekly Average'] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : commaSeperator(Math.round(competitor['weekly_average']));
        obj['No of Weeks Survived'] = challenge && challenge['challenge_type'] === 'survivor' && challenge['challenge_mode'] === 'Solo' && (!_.isNull(competitor['weekly_marks']) || !_.isUndefined(competitor['weekly_marks'])) ? competitor['weekly_marks'].filter((item)=>item !== 0).length : 0;
        obj['EMAIL'] = competitor['EMAIL'];
        obj['company_name'] = competitor['company_name'];
        return obj;
      });
    this.setState({printChallengeCSV: bool, updatedChallengeCSV: updatedChallengeCSV})
  };

  getCSVHeaders = () => {
    const {challenge} = this.props;
    const ActivityType = challenge && challenge.event_type.split(' ')[1];
    const challengeType = challenge && challenge.challenge_type;
    if(challengeType === 'new_group'){
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        {label: "Rank", key: "RANK"},
      ];
    } else{
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Company Name", key: "company_name" },
        {label: "Rank", key: "RANK"},
        {label: "Daily Average", key: "Daily Average"},
        {label: "Weekly Average", key: "Weekly Average"},
      ];
    }
    csvHeaders.push({label: `Total ${ActivityType}`, key: `Total ${ActivityType}`});
    challengeType === 'survivor' && challenge.challenge_mode === 'Solo' &&  csvHeaders.push({label: "No of Weeks Survived", key: `No of Weeks Survived`});
    return csvHeaders;
  };
  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };
  changeParticipantsModal = () => {
    this.setState((prevState) => ({
      showParticipants: !prevState.showParticipants
    }));
  }

  fallBack = (e) => {
    e.target.src = "/images/default.png";
  };

  render(){
    const {challenge, userId, /*challengeCsvData,*/ challengeCSVStatus,  /*userPermissions,*/ challengeAttendees, amigosList, t, /*role,*/ user, teamChallenge} = this.props;
    const { printChallengeCSV, updatedChallengeCSV, showParticipants} = this.state;
    if((!(this.props.userChallengeDetails) || !userId || isEmpty(challengeCSVStatus))) {
      return <Waiting />
    }
    const fileTitle = (challenge && challenge.title) && challenge.title.replace(/ /g,"_");
    // const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const ChallengeTypes = challenge.event_type;
    const activityType = ChallengeTypes === 'Total Distance' ? 'distance' : ChallengeTypes === 'Total Steps' ? 'steps' : 'calories';
    const DistanceUnit = localStorage.getItem('DISTANCE');
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    let lang = localStorage.getItem("lang");
    /*Days Left In Week*/
    return(
      <MyTaskContainerV2 margin={"1"}>
        {/* <ButtonWrapper>
          <div className="firstButton">
            <ChallengeButton>{challenge && challenge.challenge_type == 'group'?'Classic Challenge':"Group Challenge"}</ChallengeButton>
          </div>
          <div className="lastButton">
            {(!isEmpty(challengeCSVStatus) && ((challenge.is_global_challenge && role === "ADMIN") || getPermissionStatus("Download challenge CSV", userPermissions)) ) && (challenge.challenge_status == 'over' || !valid) && (challengeCsvData && challengeCsvData.length > 0)  ? <PrintButton  onClick={() => this.printAction(true)} disabled={ challengeCsvData.length <= 0 || printChallengeCSV}>
              <div><img src="/public/images/NewDashboardV2/downloadCSV.png" alt="back-arrow" /></div>
              <div >
                <span>{t("Download Challenge CSV")}</span>
              </div>
            </PrintButton> : null}
          </div>
        </ButtonWrapper> */}
        {teamChallenge && teamChallenge.team_challenge_details && teamChallenge.team_challenge_details.team_details&& teamChallenge.team_challenge_details.team_details.team_users ?<TeamMembers >
          <span className='title' style={{color:'#005C87',fontWeight:'500', fontSize:'18px',lineHeight:'24px',paddingBottom:'10px',paddingTop:"15px", fontFamily:'Rubik-Medium', display:"flex", justifyContent:"center", background:"white"}}>{this.props.t("Team Members")}</span>
          <div className='container' >
            { challenge && !isNull(teamChallenge.team_challenge_details.team_details.team_users) && teamChallenge.team_challenge_details.team_details.team_users.length > 0 &&
            teamChallenge.team_challenge_details.team_details.team_users.map((member, index)=>(
              <div key={index} style={{width:'100%'}}>
                <div  className='teamChallenge' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                    <div className="image" style={{position: "relative"}}>
                      <img   onError={this.fallBack} style={{position: "relative",borderRadius: "6px"}} src={`${ImageUrl}/${member.profile_image || 'profile-Image/default.png'}`} alt={member.fname} />
                    </div>
                    <div style={{marginLeft:'10px'}}>
                      {startDay < today && (<div className="value">
                        {t(index + 1) + rankingDashboard(index)}
                      </div>)}
                      <div className="field">
                        {member.fname + " " + member.lname} {user.uid === member.uid && (<span style={{color:'#F4AAA9',fontSize:'16px',lineHeight:'20px'}}>({this.props.t("You")})</span>)}
                        {member.is_captain === 1 && (<img style={{position: "relative",width: "24px",height: "24px",zIndex:'10', marginLeft:"15px", bottom:"2px"}} src="/public/images/captain-band.png"/>)}
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    {challenge.challenge_status === 'joined' && (startDay < today) ||  (challenge.is_completed === 1 && challenge.challenge_status == "over") ? (<span className='value' style={{fontFamily:'Rubik-medium'}}>
                      {member.is_joined === 1 ? (<React.Fragment><div style={{alignItems:'center', display:'flex', fontFamily:"Rubik-Medium"}}>
                        {isNull(member[`${activityType}`]) ? 0 :activityType === 'distance'?DistanceUnit === "KILOMETER"? `${commaSeperator(convertMilesToKm(member[`${activityType}`]))} ` : `${commaSeperator(member[`${activityType}`])} ` :`${commaSeperator(member[`${activityType}`])} `} <div className="type" style={{margin:'0px 5px 0px 5px', textTransform:'capitalize', fontFamily:"rubik"}}>{` ${activityType === 'distance' ? DistanceUnit === "KILOMETER"? 'Km' :'Miles' : t(activityType)}`}</div></div>
                      <div /></React.Fragment>):  (<span style={{color:'#F4AAA9',fontSize:'16px',lineHeight:'20px', fontFamily:"rubik"}}>{t('Not Joined Yet')}</span>) }
                      

                    </span>) : 
                      <div style={{alignItems:'center', display:'flex', }}>
                        {member.is_joined === 1 ? (<span style={{color:'#9FC989',fontSize:'16px',lineHeight:'20px'}}>{t('Joined')}</span>) : (<span style={{color:'#F4AAA9',fontSize:'16px',lineHeight:'20px', fontFamily:"rubik"}}>{t('Not Joined Yet')}</span>) }
                      </div>}
                  </div>
                </div>
                {teamChallenge.team_challenge_details.team_details.team_users.length != index + 1 && <Border margin={'0'}/>}
              </div>))
            }
          </div>
        </TeamMembers>:null}
        <ChallengeDateContainer color={"#005C87"} cursor={"1"}>
          <LazyImage src={ImageUrl + "/ChallengeDetailsScreen/calendarImage.png"} alt="back-arrow"/>
          <div>
            <div>
              <div className="attendies">{t("Start Date")}
              </div>
              <div>{ (lang != 'fr') ? convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('MMM Do, YYYY'), this.props) : convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('D MMM YYYY'), this.props)}
              </div>
            </div>
            <Border background="#005C874D"/>
            <div>
              <div>{t("End Date")}</div>
              <div>{ (lang != 'fr') ? convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('MMM Do, YYYY'), this.props) : convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('D MMM YYYY'), this.props) }</div>
            </div>
          </div>
        </ChallengeDateContainer>
        <ChallengePointContainer>
          <div>
            <div>{t("Point Value")}</div>
            <div>+{challenge.challenge_point}{t("pts")}</div>
          </div>
          <Border margin="auto 15px" background="#005C874D"/>
          <div>
            <div>{t("Location")}</div>
            <div>{t(challenge.challenge_location)}</div>
          </div>
        </ChallengePointContainer>
        <ParticipantsPopUp
          showModal={showParticipants}
          onClose={this.changeParticipantsModal}
          challengeAttendees={challengeAttendees}
          amigosList={amigosList}
        />
        {printChallengeCSV && <CSVList Title={`${fileTitle}_${challenge && challenge.id}`} removeCSVData={this.printAction} headers={this.getCSVHeaders()} data={updatedChallengeCSV} />}
        <InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1}/>
      </MyTaskContainerV2>
    )
  }
}
TeamOverViewScreen.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  teamChallenge: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  fetchTeamChallenge: PropTypes.func.isRequired,
  joinOrLeaveChallenge:PropTypes.func,
  getChallengeCsvData: PropTypes.func,
  challengeCsvData: PropTypes.array,
  getChallengeDownloadCSVStatus: PropTypes.func,
  challengeCSVStatus: PropTypes.object,
  isDummyChallenge: PropTypes.bool,
  getGroupChallengeCsvData: PropTypes.func,
  userPermissions: PropTypes.array,
  history: PropTypes.object,
  getInviteAmigosList: PropTypes.func,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getBuddiesListOfChallenges: PropTypes.func,
  amigosList: PropTypes.array,
  t: PropTypes.func,
  role: PropTypes.string,
  fetchChallengeCompetitors: PropTypes.func.isRequired,
  competitorsCount: PropTypes.number,
  challengeCompetitors: PropTypes.array,
  user: PropTypes.object,
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  challengeCsvData: state.challenges.challengeCsvData,
  challengeCSVStatus: state.challenges.challengeCSVStatus,
  isDummyChallenge: state.challenges.isDummyChallenge,
  challengeAttendees: state.challenges.challengeAttendees,
  amigosList: state.challenges.amigosList,
  challengeCompetitors: state.challenges.challengeCompetitors,
  competitorsCount: state.challenges.competitorsCount

});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getChallengeDownloadCSVStatus: (challengeId) => dispatch(getChallengeDownloadCSVStatus(challengeId)),
  getGroupChallengeCsvData: (challengeId, challengeType) => dispatch(getGroupChallengeCsvData(challengeId, challengeType)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getBuddiesListOfChallenges: (challengeId, status) => dispatch(getBuddiesListOfChallenges(challengeId, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TeamOverViewScreen));